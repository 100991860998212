import React from 'react'

import { toUSD } from '../../utils/currencyFormatter'

export interface IsoHoldSellResults {
    title: string
    costToExercise: number
    capitalGains: number
    incrementalTaxesThisYear: number
    incrementalTaxesInFutureYears: number
    proceedsNetOfExerciseCostIncrementalTaxes: number
    totalUpfrontCostsPayableThisYear: number
    totalCostsPayableFutureYears: number
    totalCosts: number
}
interface Props {
    scenarios: IsoHoldSellResults[]
}
export const IsoHoldSellResultTable = ({ scenarios }: Props) => {
    if (scenarios.length === 0) {
        return <p className='text-center'>Please fill out input data and press calculate</p>
    }

    return (
        <div className='mt-4 -mb-3'>
            <div className='not-prose relative rounded-xl overflow-auto bg-slate-900'>
                <div className='relative rounded-xl overflow-auto'>
                    <div className='shadow-sm overflow-auto my-8'>
                        <table className='border-collapse md:table-fixed w-full text-sm'>
                            <thead>
                                <tr>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    ></th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <th
                                                key={`col-header-${index}`}
                                                scope='col'
                                                className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                            >
                                                {scenario.title}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody className='bg-slate-800'>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Cost to Exercise Options
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`costToExercise-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.costToExercise)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Capital Gains
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`capitalGains-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.capitalGains)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Incremental Taxes From Selling Payable This Year
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`incrementalTaxesThisYear-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.incrementalTaxesThisYear)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Incremental Taxes From Selling payable In Future Years
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`incrementalTaxesInFutureYears-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.incrementalTaxesInFutureYears)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Proceeds Net of Exercise Cost & Incremental Taxes
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`proceedsNetOfExerciseCostIncrementalTaxes-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(
                                                    scenario.proceedsNetOfExerciseCostIncrementalTaxes,
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Total Upfront Costs Payable This Year (incl taxes)
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`totalUpfrontCostsPayableThisYear-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.totalUpfrontCostsPayableThisYear)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Total Upfront Costs Payable Future Years (i.e. taxes)
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`totalCostsPayableFutureYears-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.totalCostsPayableFutureYears)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Total Costs
                                    </th>
                                    {scenarios.map((scenario, index) => {
                                        return (
                                            <td
                                                key={`totalCosts-${index}`}
                                                className='border-b border-slate-600 p-4 pl-8 text-slate-200'
                                            >
                                                {toUSD(scenario.totalCosts)}
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
