import React, { useState } from 'react'
import { useForm, FieldValues, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'

import { NumInput, StateInput } from '../inputs'
import { IsoHoldSellResultTable, IsoHoldSellResults } from '../Tables'
import {
    federalTaxAmount,
    newYorkTaxAmount,
    amtTaxAmount,
    ltcgTaxAmount,
} from '../../utils/taxCalculator'

interface IFormInputs extends FieldValues {
    fmvPerShare: number
    ipoSharePrice: number
    grantPrice: number
    numOfOptions: number
    stateOfResidence: string
    cashComp: number
}

const schema = yup
    .object({
        fmvPerShare: yup.number().positive().required(),
        ipoSharePrice: yup.number().positive().required(),
        grantPrice: yup.number().positive().required(),
        numOfOptions: yup.number().positive().integer().required(),
        stateOfResidence: yup.string().required(),
        cashComp: yup.number().positive().required(),
    })
    .required()

// Calculate the total amount owed in taxes
const totalTaxAmountInYear = (
    cashIncome: number,
    shareSaleCapitalGains: number,
    equityIncome: number,
) => {
    const totalIncome = cashIncome + shareSaleCapitalGains - equityIncome
    const federalTaxes = federalTaxAmount(totalIncome)
    const stateTaxes = newYorkTaxAmount(totalIncome)
    const statePlusFederalTaxes = federalTaxes + stateTaxes
    const amtTaxes = amtTaxAmount(totalIncome)
    const totalTaxes = Math.max(statePlusFederalTaxes, amtTaxes) * -1
    const postTaxIncome = totalIncome + totalTaxes
    const effectiveTaxRate = Math.abs(totalTaxes / totalIncome)

    return {
        totalIncome,
        federalTaxes,
        stateTaxes,
        statePlusFederalTaxes,
        amtTaxes,
        totalTaxes,
        postTaxIncome,
        effectiveTaxRate,
    }
}

export const HoldSellForm = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            fmvPerShare: 3.0,
            ipoSharePrice: 10.0,
            grantPrice: 1.0,
            numOfOptions: 10000,
            stateOfResidence: 'NY',
            cashComp: 200000,
            grantDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            exerciseDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 11,
                new Date().getDate(),
            ),
            saleDate: new Date(),
        },
    })

    const [scenarios, setScenarios] = useState<IsoHoldSellResults[]>([])

    const onSubmit = (data: IFormInputs) => {
        const scenarios: IsoHoldSellResults[] = []
        // calculate scenario 1
        const scenario1CostToExerciseOptions = data.fmvPerShare * data.numOfOptions
        const scenario1GrossSaleProceeds = data.ipoSharePrice * data.numOfOptions
        const scenario1CapitalGains = scenario1GrossSaleProceeds - scenario1CostToExerciseOptions
        const scenario1Taxes = totalTaxAmountInYear(data.cashComp, 0, 0)
        const scenario1SaleTaxes = totalTaxAmountInYear(data.cashComp, scenario1CapitalGains, 0)
        const scenario1IncrementalTaxesThisYear =
            scenario1SaleTaxes.statePlusFederalTaxes - scenario1Taxes.statePlusFederalTaxes
        const scenario1IncrementalTaxesInFutureYears = 0
        const scenario1ProceedsNetOfExerciseCostIncrementalTaxes =
            scenario1CapitalGains -
            scenario1IncrementalTaxesThisYear -
            scenario1IncrementalTaxesInFutureYears
        const scenario1TotalUpfrontCostsPayableThisYear =
            scenario1CostToExerciseOptions + scenario1IncrementalTaxesThisYear
        const scenario1TotalCostsPayableFutureYears = scenario1IncrementalTaxesInFutureYears
        scenarios.push({
            title: 'Net Proceeds from Share Sale Now or Within Calendar Year',
            costToExercise: scenario1CostToExerciseOptions,
            capitalGains: scenario1CapitalGains,
            incrementalTaxesThisYear: scenario1IncrementalTaxesThisYear,
            incrementalTaxesInFutureYears: scenario1IncrementalTaxesInFutureYears,
            proceedsNetOfExerciseCostIncrementalTaxes:
                scenario1ProceedsNetOfExerciseCostIncrementalTaxes,
            totalUpfrontCostsPayableThisYear: scenario1TotalUpfrontCostsPayableThisYear,
            totalCostsPayableFutureYears: scenario1TotalCostsPayableFutureYears,
            totalCosts:
                scenario1TotalUpfrontCostsPayableThisYear + scenario1TotalCostsPayableFutureYears,
        })

        // calculate scenario 2
        const scenario2CostToExerciseOptions = data.fmvPerShare * data.numOfOptions
        const scenario2CapitalGains = (data.ipoSharePrice - data.fmvPerShare) * data.numOfOptions
        const scenario2Taxes = totalTaxAmountInYear(data.cashComp, 0, 0)
        const scenario2SaleTaxes = totalTaxAmountInYear(data.cashComp, scenario2CapitalGains, 0)
        const scenario2IncrementalTaxesThisYear =
            scenario2Taxes.totalTaxes * -1 > scenario2Taxes.statePlusFederalTaxes
                ? scenario2Taxes.amtTaxes - scenario2Taxes.statePlusFederalTaxes
                : 0
        const scenario2IncrementalTaxesInFutureYears =
            scenario2SaleTaxes.statePlusFederalTaxes - scenario2Taxes.statePlusFederalTaxes
        const scenario2ProceedsNetOfExerciseCostIncrementalTaxes =
            scenario2CapitalGains -
            scenario2IncrementalTaxesThisYear -
            scenario2IncrementalTaxesInFutureYears
        const scenario2TotalUpfrontCostsPayableThisYear =
            scenario2CostToExerciseOptions + scenario2IncrementalTaxesThisYear
        const scenario2TotalCostsPayableFutureYears = scenario2IncrementalTaxesInFutureYears
        scenarios.push({
            title: 'Net Proceeds from Share Sale In <1 yr from Exercise but in Following Calendar Year',
            costToExercise: scenario2CostToExerciseOptions,
            capitalGains: scenario2CapitalGains,
            incrementalTaxesThisYear: scenario2IncrementalTaxesThisYear,
            incrementalTaxesInFutureYears: scenario2IncrementalTaxesInFutureYears,
            proceedsNetOfExerciseCostIncrementalTaxes:
                scenario2ProceedsNetOfExerciseCostIncrementalTaxes,
            totalUpfrontCostsPayableThisYear: scenario2TotalUpfrontCostsPayableThisYear,
            totalCostsPayableFutureYears: scenario2TotalCostsPayableFutureYears,
            totalCosts:
                scenario2TotalUpfrontCostsPayableThisYear + scenario2TotalCostsPayableFutureYears,
        })

        // calculate scenario 3
        const scenario3CostToExerciseOptions = data.fmvPerShare * data.numOfOptions
        const scenario3CapitalGains = (data.ipoSharePrice - data.fmvPerShare) * data.numOfOptions
        const scenario3Taxes = totalTaxAmountInYear(data.cashComp, 0, 0)
        const scenario3IncrementalTaxesThisYear =
            scenario3Taxes.totalTaxes * -1 > scenario3Taxes.statePlusFederalTaxes
                ? scenario3Taxes.amtTaxes - scenario3Taxes.statePlusFederalTaxes
                : 0
        const scenario3IncrementalTaxesInFutureYears = ltcgTaxAmount(scenario3CapitalGains)
        const scenario3ProceedsNetOfExerciseCostIncrementalTaxes =
            scenario3CapitalGains -
            scenario3IncrementalTaxesThisYear -
            scenario3IncrementalTaxesInFutureYears
        const scenario3TotalUpfrontCostsPayableThisYear =
            scenario3CostToExerciseOptions + scenario3IncrementalTaxesThisYear
        const scenario3TotalCostsPayableFutureYears = scenario3IncrementalTaxesInFutureYears
        scenarios.push({
            title: 'Net Proceeds from Share Sale After >1 yr from Exercise but <2 yrs since Grant Date and in Following Calendar Year',
            costToExercise: scenario3CostToExerciseOptions,
            capitalGains: scenario3CapitalGains,
            incrementalTaxesThisYear: scenario3IncrementalTaxesThisYear,
            incrementalTaxesInFutureYears: scenario3IncrementalTaxesInFutureYears,
            proceedsNetOfExerciseCostIncrementalTaxes:
                scenario3ProceedsNetOfExerciseCostIncrementalTaxes,
            totalUpfrontCostsPayableThisYear: scenario3TotalUpfrontCostsPayableThisYear,
            totalCostsPayableFutureYears: scenario3TotalCostsPayableFutureYears,
            totalCosts:
                scenario3TotalUpfrontCostsPayableThisYear + scenario3TotalCostsPayableFutureYears,
        })

        // calculate scenario 4
        const scenario4CostToExerciseOptions = data.fmvPerShare * data.numOfOptions
        const scenario4CapitalGains = (data.ipoSharePrice - data.fmvPerShare) * data.numOfOptions
        const scenario4Taxes = totalTaxAmountInYear(data.cashComp, 0, 0)
        const scenario4IncrementalTaxesThisYear =
            scenario4Taxes.totalTaxes * -1 > scenario4Taxes.statePlusFederalTaxes
                ? scenario4Taxes.amtTaxes - scenario4Taxes.statePlusFederalTaxes
                : 0
        const scenario4IncrementalTaxesInFutureYears = ltcgTaxAmount(scenario4CapitalGains)
        const scenario4ProceedsNetOfExerciseCostIncrementalTaxes =
            scenario4CapitalGains -
            scenario4IncrementalTaxesThisYear -
            scenario4IncrementalTaxesInFutureYears
        const scenario4TotalUpfrontCostsPayableThisYear =
            scenario4CostToExerciseOptions + scenario4IncrementalTaxesThisYear
        const scenario4TotalCostsPayableFutureYears = scenario4IncrementalTaxesInFutureYears
        scenarios.push({
            title: 'Net Proceeds from Share Sale After >1 yr from Exercise but >2 yrs since Grant Date and in Following Calendar Year',
            costToExercise: scenario4CostToExerciseOptions,
            capitalGains: scenario4CapitalGains,
            incrementalTaxesThisYear: scenario4IncrementalTaxesThisYear,
            incrementalTaxesInFutureYears: scenario4IncrementalTaxesInFutureYears,
            proceedsNetOfExerciseCostIncrementalTaxes:
                scenario4ProceedsNetOfExerciseCostIncrementalTaxes,
            totalUpfrontCostsPayableThisYear: scenario4TotalUpfrontCostsPayableThisYear,
            totalCostsPayableFutureYears: scenario4TotalCostsPayableFutureYears,
            totalCosts:
                scenario4TotalUpfrontCostsPayableThisYear + scenario4TotalCostsPayableFutureYears,
        })

        setScenarios(scenarios)
    }

    return (
        <div className='sm:rounded-md p-8 mx-5 mb-5 bg-slate-700'>
            <div className='grid grid-cols-1 md:grid-cols-3 md:gap-6'>
                <div className='col-span-1 md:col-span-1'>
                    <div className='px-4 sm:px-0'>
                        <h1 className='inline-block text-2xl sm:text-3xl font-extrabold tracking-tight text-slate-200'>
                            ISO Calculator - Timing of Share Sale Following Option Exercise
                        </h1>
                        <p className='italic mt-2 text-lg text-slate-400'>
                            After exercising options, the timing of share sale can have significant
                            tax implications. Generally, you receive preferred tax treatment if you
                            wait 2 yrs or more to sell
                        </p>
                    </div>
                </div>
                <div className='mt-5 col-span-1 md:col-span-2 md:mt-0'>
                    <form
                        className='shadow-md rounded px-8 pt-6 pb-8 mb-4 text-slate-400 bg-slate-900'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='shadow sm:rounded-md'>
                            <div className='px-4 py-5 sm:p-6'>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                    <Controller
                                        name='fmvPerShare'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='FMV Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.fmvPerShare?.message}
                                                placeholder='0.00'
                                                tooltip='i.e. 409a Price'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='ipoSharePrice'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='IPO Price Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.ipoSharePrice?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='grantPrice'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='Grant Price Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.grantPrice?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='numOfOptions'
                                        control={control}
                                        render={({ field }) => (
                                            <NumInput
                                                label='Number of Options'
                                                {...field}
                                                errorMessage={errors.numOfOptions?.message}
                                                placeholder='1000'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='stateOfResidence'
                                        control={control}
                                        render={({ field }) => (
                                            <StateInput
                                                label='State of Residence'
                                                {...field}
                                                errorMessage={errors.stateOfResidence?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='cashComp'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='Cash Compensation'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.cashComp?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <button
                                        type='submit'
                                        className='items-center col-span-1 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                    >
                                        Calculate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='grid grid-cols-1'>
                <h1 className='inline-block text-2l sm:text-2xl font-extrabold tracking-tight text-slate-200 text-center w-full'>
                    Net Proceeds in Different Scenarios
                </h1>
                <IsoHoldSellResultTable scenarios={scenarios} />
            </div>
        </div>
    )
}
