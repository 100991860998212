// For federal tax rate, taxes due April 2023
// TODO: go over with ken his amounts vary from mine
export const federalTaxAmount = (amount: number) => {
    const brackets = [
        { maxAmount: 0, rate: 0 },
        { maxAmount: 10275, rate: 0.1 },
        { maxAmount: 41775, rate: 0.12 },
        { maxAmount: 89075, rate: 0.22 },
        { maxAmount: 170050, rate: 0.24 },
        { maxAmount: 215950, rate: 0.32 },
        { maxAmount: 539900, rate: 0.35 },
        { maxAmount: Infinity, rate: 0.37 },
    ]

    return calculateTaxAmount(amount, brackets)
}

export const calculateTaxAmount = (
    amount: number,
    brackets: { maxAmount: number; rate: number }[],
) => {
    let taxAmount = 0

    for (let index = 1; index < brackets.length; index++) {
        if (amount > brackets[index].maxAmount) {
            taxAmount +=
                (brackets[index].maxAmount - brackets[index - 1].maxAmount) * brackets[index].rate
        } else {
            taxAmount += (amount - brackets[index - 1].maxAmount) * brackets[index].rate
            return taxAmount
        }
    }

    return taxAmount
}

// For NY tax rate, taxes due April 2023
export const newYorkTaxAmount = (amount: number) => {
    const brackets = [
        { maxAmount: 0, rate: 0 },
        { maxAmount: 8500, rate: 0.04 },
        { maxAmount: 11700, rate: 0.045 },
        { maxAmount: 13900, rate: 0.0525 },
        { maxAmount: 21400, rate: 0.059 },
        { maxAmount: 80650, rate: 0.0597 },
        { maxAmount: 215400, rate: 0.0633 },
        { maxAmount: 1077550, rate: 0.0685 },
        { maxAmount: 5000000, rate: 0.0965 },
        { maxAmount: 25000000, rate: 0.103 },
        { maxAmount: Infinity, rate: 0.109 },
    ]

    return calculateTaxAmount(amount, brackets)
}

// For AMT tax rate, taxes due April 2023
export const amtTaxAmount = (amount: number) => {
    const brackets = [
        { maxAmount: 0, rate: 0 },
        { maxAmount: 75900, rate: 0.0 },
        { maxAmount: 206099, rate: 0.26 },
        { maxAmount: Infinity, rate: 0.28 },
    ]

    return calculateTaxAmount(amount, brackets)
}

// For Long Term Captial Gains tax rate, taxes due April 2023
export const ltcgTaxAmount = (amount: number) => {
    const brackets = [
        { maxAmount: 0, rate: 0 },
        { maxAmount: 41675, rate: 0.0 },
        { maxAmount: 459750, rate: 0.15 },
        { maxAmount: Infinity, rate: 0.22 },
    ]

    return calculateTaxAmount(amount, brackets)
}
