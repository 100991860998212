import React, { Ref, SelectHTMLAttributes } from 'react'

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
    name: string
    label: string
    errorMessage?: string
}

const StateInnerInput = (
    { name, label, errorMessage, ...rest }: InputProps,
    ref: Ref<HTMLSelectElement>,
): JSX.Element => {
    return (
        <div className='col-span-1'>
            <label className='block text-sm font-medium' htmlFor={name}>
                {label}
            </label>
            <select
                name={name}
                {...rest}
                ref={ref}
                className='text-slate-900 mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            >
                <option value='NY'>New York</option>
            </select>
            <p>{errorMessage}</p>
        </div>
    )
}

export const StateInput = React.forwardRef(StateInnerInput)
