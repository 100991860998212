import React, { useState } from 'react'
import { useForm, FieldValues, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'

import { NumInput, StateInput } from '../inputs'
import { IsoExerciseResultTable, IsoExerciseResults } from '../Tables'
import { federalTaxAmount, newYorkTaxAmount, amtTaxAmount } from '../../utils/taxCalculator'

interface IFormInputs extends FieldValues {
    fmvPerShare: number
    ipoSharePrice: number
    grantPrice: number
    numOfOptions: number
    stateOfResidence: string
    cashComp: number
}

const schema = yup
    .object({
        fmvPerShare: yup.number().positive().required(),
        ipoSharePrice: yup.number().positive().required(),
        grantPrice: yup.number().positive().required(),
        numOfOptions: yup.number().positive().integer().required(),
        stateOfResidence: yup.string().required(),
        cashComp: yup.number().positive().required(),
    })
    .required()

// Calculate the total amount owed in taxes
const totalTaxAmountInYear = (
    cashIncome: number,
    shareSaleCapitalGains: number,
    equityIncome: number,
) => {
    const totalIncome = cashIncome + shareSaleCapitalGains + equityIncome
    const federalTaxes = federalTaxAmount(cashIncome + shareSaleCapitalGains)
    const stateTaxes = newYorkTaxAmount(cashIncome + shareSaleCapitalGains)
    const statePlusFederalTaxes = federalTaxes + stateTaxes
    const amtTaxes = amtTaxAmount(totalIncome)
    const totalTaxes = Math.max(statePlusFederalTaxes, amtTaxes) * -1
    const postTaxIncome = totalIncome + totalTaxes
    const effectiveTaxRate = Math.abs(totalTaxes / totalIncome)

    return {
        totalIncome,
        federalTaxes,
        stateTaxes,
        statePlusFederalTaxes,
        amtTaxes,
        totalTaxes,
        postTaxIncome,
        effectiveTaxRate,
    }
}
export const ExerciseForm = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            fmvPerShare: 3.0,
            ipoSharePrice: 10.0,
            grantPrice: 1.0,
            numOfOptions: 10000,
            stateOfResidence: 'NY',
            cashComp: 200000,
        },
    })

    const [exerciseNowValues, setExerciseNowValues] = useState<IsoExerciseResults>({
        costToExercise: 0,
        incrementalTaxesForExercising: 0,
        upFrontCost: 0,
        taxesOnExitAtIpo: 0,
        totalCost: 0,
        totalPostTaxCapitalGains: 0,
    })

    const [exerciseAtIPOValues, setExerciseAtIPOValues] = useState<IsoExerciseResults>({
        costToExercise: 0,
        incrementalTaxesForExercising: 0,
        upFrontCost: 0,
        taxesOnExitAtIpo: 0,
        totalCost: 0,
        totalPostTaxCapitalGains: 0,
    })
    const onSubmit = (data: IFormInputs) => {
        const costToExercise = data.grantPrice * data.numOfOptions

        const nowEquityIncome = (data.fmvPerShare - data.grantPrice) * data.numOfOptions
        const nowTaxAndCompValues = totalTaxAmountInYear(data.cashComp, 0, nowEquityIncome)
        const nowITFE =
            Math.abs(nowTaxAndCompValues.totalTaxes) - nowTaxAndCompValues.statePlusFederalTaxes
        const nowUpFrontCost = costToExercise + nowITFE
        const nowTaxesOnExitAtIpo =
            (data.ipoSharePrice - data.fmvPerShare) * data.numOfOptions * 0.32 // TODO calculate taxes
        const nowTotalCost = nowUpFrontCost + nowTaxesOnExitAtIpo
        const nowTotalPostTaxCapitalGains = data.ipoSharePrice * data.numOfOptions - nowTotalCost

        setExerciseNowValues({
            costToExercise,
            incrementalTaxesForExercising: nowITFE,
            upFrontCost: nowUpFrontCost,
            taxesOnExitAtIpo: nowTaxesOnExitAtIpo,
            totalCost: nowTotalCost,
            totalPostTaxCapitalGains: nowTotalPostTaxCapitalGains,
        })

        const ipoEquityIncome = (data.ipoSharePrice - data.grantPrice) * data.numOfOptions
        const ipoTaxAndCompValues = totalTaxAmountInYear(data.cashComp, 0, ipoEquityIncome)
        const ipoITFE =
            Math.abs(ipoTaxAndCompValues.totalTaxes) - ipoTaxAndCompValues.statePlusFederalTaxes
        const ipoUpFrontCost = costToExercise + ipoITFE
        const ipoTaxesOnExitAtIpo =
            (data.ipoSharePrice - data.grantPrice) * data.numOfOptions * 0.32 // TODO calculate taxes
        const ipoTotalCost = ipoUpFrontCost + ipoTaxesOnExitAtIpo
        const ipoTotalPostTaxCapitalGains = data.ipoSharePrice * data.numOfOptions - ipoTotalCost

        setExerciseAtIPOValues({
            costToExercise,
            incrementalTaxesForExercising: ipoITFE,
            upFrontCost: ipoUpFrontCost,
            taxesOnExitAtIpo: ipoTaxesOnExitAtIpo,
            totalCost: ipoTotalCost,
            totalPostTaxCapitalGains: ipoTotalPostTaxCapitalGains,
        })
    }

    return (
        <div className='sm:rounded-md p-8 mx-5 mb-5 bg-slate-700'>
            <div className='grid grid-cols-1 md:grid-cols-3 md:gap-6'>
                <div className='col-span-1 md:col-span-1'>
                    <div className='px-4 sm:px-0'>
                        <h1 className='inline-block text-2xl sm:text-3xl font-extrabold tracking-tight text-slate-200'>
                            ISO Calculator - Timing of Options Exercise
                        </h1>
                        <p className='italic mt-2 text-lg text-slate-400'>
                            Options are generally more valuable when exercised sooner given that
                            taxes will be greater if exercised at a later date when the valuation
                            has likely increased. Use this calculator to see how timing can impact
                            value from your options.
                        </p>
                    </div>
                </div>
                <div className='mt-5 col-span-1 md:col-span-2 md:mt-0'>
                    <form
                        className='shadow-md rounded px-8 pt-6 pb-8 mb-4 text-slate-400 bg-slate-900'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='overflow-hidden shadow sm:rounded-md'>
                            <div className='px-4 py-5 sm:p-6'>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                    <Controller
                                        name='fmvPerShare'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='FMV Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.fmvPerShare?.message}
                                                placeholder='0.00'
                                                tooltip='i.e. 409a Price'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='ipoSharePrice'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='IPO Price Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.ipoSharePrice?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='grantPrice'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='Grant Price Per Share'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.grantPrice?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='numOfOptions'
                                        control={control}
                                        render={({ field }) => (
                                            <NumInput
                                                label='Number of Options'
                                                {...field}
                                                errorMessage={errors.numOfOptions?.message}
                                                placeholder='1000'
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='stateOfResidence'
                                        control={control}
                                        render={({ field }) => (
                                            <StateInput
                                                label='State of Residence'
                                                {...field}
                                                errorMessage={errors.stateOfResidence?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name='cashComp'
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, name, value, ref },
                                        }) => (
                                            <NumericFormat
                                                customInput={NumInput}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                label='Cash Compensation'
                                                onValueChange={(values) =>
                                                    onChange(values.floatValue)
                                                }
                                                value={value}
                                                name={name}
                                                onBlur={onBlur}
                                                getInputRef={ref}
                                                errorMessage={errors.cashComp?.message}
                                                placeholder='0.00'
                                            />
                                        )}
                                    />
                                    <button
                                        type='submit'
                                        className='col-span-1 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                    >
                                        Calculate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='grid grid-cols-1'>
                <h1 className='inline-block text-2l sm:text-2xl font-extrabold tracking-tight text-slate-200 text-center w-full'>
                    Cost To Exercise Options
                </h1>
                <IsoExerciseResultTable now={exerciseNowValues} ipo={exerciseAtIPOValues} />
            </div>
        </div>
    )
}
