import React from 'react'

import { toUSD } from '../../utils/currencyFormatter'

export interface IsoExerciseResults {
    costToExercise: number
    incrementalTaxesForExercising: number
    upFrontCost: number
    taxesOnExitAtIpo: number
    totalCost: number
    totalPostTaxCapitalGains: number
}
interface Props {
    now: IsoExerciseResults
    ipo: IsoExerciseResults
}
export const IsoExerciseResultTable = ({ now, ipo }: Props) => {
    return (
        <div className='mt-4 -mb-3'>
            <div className='not-prose relative rounded-xl overflow-auto bg-slate-900'>
                <div className='relative rounded-xl overflow-auto'>
                    <div className='shadow-sm overflow-auto my-8'>
                        <table className='border-collapse md:table-fixed w-full text-sm'>
                            <thead>
                                <tr>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        When
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Cost to exercise
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Incremental Taxes from Exercising
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Upfront Cost
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Taxes On Exit at IPO
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Total Cost
                                    </th>
                                    <th
                                        scope='col'
                                        className='border-b border-slate-600 md:font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'
                                    >
                                        Total Post Tax Capital Gains
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='bg-slate-800'>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        Now
                                    </th>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.costToExercise)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.incrementalTaxesForExercising)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.upFrontCost)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.taxesOnExitAtIpo)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.totalCost)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(now.totalPostTaxCapitalGains)}
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        scope='row'
                                        className='border-b border-slate-600 p-4 pl-8 text-slate-200 text-left'
                                    >
                                        At IPO
                                    </th>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.costToExercise)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.incrementalTaxesForExercising)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.upFrontCost)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.taxesOnExitAtIpo)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.totalCost)}
                                    </td>
                                    <td className='border-b border-slate-600 p-4 pl-8 text-slate-200'>
                                        {toUSD(ipo.totalPostTaxCapitalGains)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
