import React from 'react'

import { Header } from './components/header'
import { ExerciseForm, HoldSellForm } from './components/forms'

function App() {
    return (
        <div className='flex flex-col items-center min-h-screen antialiased text-slate-400 bg-slate-900 vsc-initialized'>
            <Header />
            <ExerciseForm />
            <HoldSellForm />
        </div>
    )
}

export default App
