import React from 'react'

export const Header = () => {
    return (
        <div className='py-20'>
            <div className='container mx-auto px-6'>
                <h1 className='inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    Incentive Stock Options(ISO) Calculator
                </h1>
                <p className='mt-2 text-lg text-slate-700 dark:text-slate-400'>
                    This website is meant as a tool for startup employees who own Incentive Stock
                    Options(ISOs) and want to calculate their potential value.
                </p>
            </div>
        </div>
    )
}
