import React, { Ref, InputHTMLAttributes } from 'react'
import { Tooltip } from '@material-tailwind/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    errorMessage?: string
    tooltip?: string
}

export const NumInnerInput = (
    { name, label, errorMessage, tooltip, ...rest }: InputProps,
    ref: Ref<HTMLInputElement>,
): JSX.Element => {
    return (
        <div className='col-span-1'>
            <div className='flex items-center justify-left'>
                <label className='block text-sm font-medium pr-2' htmlFor={name}>
                    {label}
                </label>
                {tooltip && (
                    <Tooltip content={tooltip}>
                        <InformationCircleIcon className='h-4 w-4' />
                    </Tooltip>
                )}
            </div>
            <input
                name={name}
                type='text'
                {...rest}
                ref={ref}
                className='text-slate-900 mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            />
            <p>{errorMessage}</p>
        </div>
    )
}

export const NumInput = React.forwardRef(NumInnerInput)
